import React from 'react';
import { useLocation } from '@gatsbyjs/reach-router';
import { useTranslation } from 'react-i18next';
import setLanguage from 'src/utils/setLanguage';
import useComponentWillMount from 'src/hooks/useComponentWillMount';

import Layout from 'src/components/Layout';
import LostPassword from 'src/components/LostPassword/LostPassword';

function LostPasswordPage() {
  const location = useLocation();
  const {i18n} = useTranslation();
  useComponentWillMount(() => {setLanguage(location.pathname, i18n)});
  return (
    <Layout>
      <meta name="viewport" content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
      <meta name="robots" content="noindex" />

      <LostPassword />
    </Layout>
  )
}


export default LostPasswordPage;
